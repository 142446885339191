import React, { FC, useContext } from 'react';
import { AppState, AuthContext } from '../stores/AuthStore';
import LoginForm from './modules/LoginForm';
import CrmAppBar from './modules/CrmAppBar';
import DecliningCaseNumbers from './modules/DecliningCaseNumbers';
import { Route, Routes } from 'react-router-dom';
import Dashboard from './Dashboard';
import Contacts from './Contacts';
import ContactView from './ContactView';
import ContactPersonView from './ContactPersonView';
import ContactPersons from './ContactPersons';
import Users from './Users';
import Reports from './Reports';
import RouteRestricted from './helpers/RouteRestricted';
import CrmBackdrop from './helpers/CrmBackdrop';
import Statistics from './Statistics';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorHandling from './ErrorHandling';
import logError from '../errors/logError';
import NotFound from './NotFound';
import Me from './Me';
import DataSetOverView, { baseUrl as baseDataUrl } from './DataSetOverView';
import Rivals from './Rivals';
import RivalView from './RivalView';
import ContactsMap from './ContactsMap';
import AboptimalUsers from './AboptimalUsers';
import RentalCarUsage from './RentalCarUsage';
import { Box, Container } from '@mui/material';
import DataSetEditor from './modules/DataSet/DataSetEditor';

const Main: FC = () => {
    const { state } = useContext(AuthContext);

    const onResetApp: () => void = () => {
        window.location.reload();
    };

    return (
        <ErrorBoundary
            FallbackComponent={ErrorHandling}
            onReset={onResetApp}
            onError={logError}
        >
            {state === AppState.loading && <span>Loading ...</span>}
            {state === AppState.unauthenticated && <LoginForm />}
            {state === AppState.authenticated && (
                <>
                    <CrmBackdrop />
                    <CrmAppBar />
                    <Container component="main">
                        <Box my={5}>
                            <Routes>
                                <Route path="/" element={<Dashboard />} />

                                <Route path="/me" element={<Me />} />

                                <Route
                                    path="/contacts"
                                    element={<Contacts />}
                                />
                                <Route
                                    path="/contact/:contactId"
                                    element={<ContactView />}
                                />
                                <Route
                                    path="/contactsMap"
                                    element={<ContactsMap />}
                                />
                                <Route
                                    path="/contactsMap/:contactId"
                                    element={<ContactsMap />}
                                />

                                <Route path="/rivals" element={<Rivals />} />
                                <Route
                                    path="/rival/:rivalId"
                                    element={<RivalView />}
                                />

                                <Route
                                    path="/contact_persons"
                                    element={<ContactPersons />}
                                />
                                <Route
                                    path="/contact_person/:contactPersonId"
                                    element={<ContactPersonView />}
                                />

                                <Route
                                    path="/statistics"
                                    element={<Statistics />}
                                />

                                <Route
                                    path="/users"
                                    element={
                                        <RouteRestricted permission="user_administration">
                                            <Users />
                                        </RouteRestricted>
                                    }
                                />

                                <Route
                                    path="/user/:userId"
                                    element={
                                        <RouteRestricted permission="user_administration">
                                            <Users />
                                        </RouteRestricted>
                                    }
                                />

                                <Route
                                    path="/offices"
                                    element={
                                        <RouteRestricted permission="user_administration">
                                            <Users />
                                        </RouteRestricted>
                                    }
                                />

                                <Route path="/reports" element={<Reports />} />

                                <Route
                                    path="/declining_cases/declining_case_numbers"
                                    element={<DecliningCaseNumbers />}
                                />
                                <Route
                                    path={`${baseDataUrl}:slug?/:id?`}
                                    element={<DataSetEditor />}
                                />
                                <Route
                                    index
                                    path={baseDataUrl}
                                    element={<DataSetOverView />}
                                />

                                <Route
                                    path="/settings/aboptimalUsers"
                                    element={<AboptimalUsers />}
                                />

                                <Route
                                    path="/rentalCarUsage"
                                    element={<RentalCarUsage />}
                                />

                                <Route element={<NotFound />} />
                            </Routes>
                        </Box>
                    </Container>
                </>
            )}
        </ErrorBoundary>
    );
};

export default Main;
