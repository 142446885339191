import React, { FC, useEffect, useMemo, useState } from 'react';
import {
    Link,
    Navigate,
    NavigateFunction,
    useNavigate,
    useParams,
} from 'react-router-dom';
import CrmCard from '../../helpers/CrmCard';
import { baseUrl, DataSet, dataSets } from '../../DataSetOverView';
import {
    Button,
    Grid,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Tooltip,
    Typography,
} from '@material-ui/core';
import Ajax from '../../../helpers/Ajax';
import logError from '../../../errors/logError';
import { useAsync } from 'react-use';
import { useSnackbar } from 'notistack';
import { Field } from 'formik';
import FormBuilder from '../../helpers/forms/FormBuilder';
import { TextField } from 'formik-material-ui';
import { Add, ChevronLeft, Delete, Save } from '@material-ui/icons';
import DeleteAction from '../../helpers/actions/DeleteAction';

interface DataSetItem {
    id: string;
    name: string;
    attributes: Obj;
    relations: number;
}

const DataSetEditor: FC = () => {
    const { slug, id = null } = useParams<{ slug: string; id: string }>();
    const [items, setItems] = useState<DataSetItem[] | null>(null);
    const [item, setItem] = useState<DataSetItem | null>(null);
    const navigate: NavigateFunction = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const baseUrlEditor: string = `${baseUrl}${slug}`;

    const dataset: DataSet | null = useMemo(
        () => dataSets.find(data => data.slug === slug) ?? null,
        [slug]
    );

    const fetch: () => Promise<void> = async () => {
        await setItems(null);

        if (dataset === null) {
            return;
        }

        try {
            const items: DataSetItem[] = await Ajax.get<DataSetItem[]>(
                `data-sets/${dataset?.slug}`
            );
            setItems(items);
        } catch (error) {
            enqueueSnackbar(
                `Fehler bei der Abfrage: ${
                    error instanceof Error
                        ? error?.message
                        : 'Unbekannter Fehler'
                }`,
                {
                    variant: 'error',
                }
            );
            logError(error);
        }
    };

    useAsync(fetch, [dataset]);

    useEffect(() => {
        const item: DataSetItem | null =
            dataset && id && id !== 'new'
                ? (items?.find(item => item.id === id) ?? null)
                : null;
        setItem(item);
    }, [id, items?.length]);

    const onSave: (response: DataSetItem) => Promise<void> = async response => {
        await onSuccess(`"${response.name}" gespeichert`);
    };

    const onDelete: () => Promise<void> = async () => {
        await onSuccess(`"${dataset?.singular}" gelöscht`);
    };

    const onSuccess: (message: string) => Promise<void> = async message => {
        enqueueSnackbar(message, {
            variant: 'success',
        });
        await fetch();
        navigate(baseUrlEditor);
    };

    if (dataset === null) {
        return <Navigate to={baseUrl} replace />;
    }

    const initialValues: Obj = {};
    dataset.attributes.forEach(attribute => {
        initialValues[attribute] = item?.attributes[attribute] ?? '';
    });

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Button
                    color="primary"
                    component={Link}
                    to={baseUrl}
                    startIcon={<ChevronLeft />}
                >
                    Zurück zur Stammdaten-Übersicht
                </Button>
            </Grid>
            <Grid item xs={6}>
                <CrmCard
                    title={`Alle ${dataset.plural}`}
                    titleAction={
                        <IconButton
                            color="secondary"
                            component={Link}
                            to={`${baseUrlEditor}/new`}
                        >
                            <Add />
                        </IconButton>
                    }
                >
                    {items === null && <LinearProgress />}
                    {items?.length === 0 && <>Keine Einträge</>}
                    <List dense disablePadding>
                        {items?.map(item => (
                            <ListItem
                                key={item.id}
                                button
                                disableGutters
                                component={Link}
                                to={`${baseUrlEditor}/${item.id}`}
                            >
                                <ListItemText primary={item.name} />
                                {item.relations > 0 && (
                                    <ListItemSecondaryAction>
                                        <Typography color="textSecondary">
                                            {item.relations}
                                        </Typography>
                                    </ListItemSecondaryAction>
                                )}
                            </ListItem>
                        ))}
                    </List>
                </CrmCard>
            </Grid>
            <Grid item xs={6}>
                {id !== null && (
                    <FormBuilder
                        action={`data-sets/${dataset.slug}/${
                            id !== 'new' ? id : ''
                        }`}
                        initialValues={initialValues}
                        enableReinitialize
                        responseHandler={onSave}
                    >
                        <CrmCard
                            title={
                                item !== null
                                    ? `"${item?.name}" bearbeiten`
                                    : `${dataset.singular} anlegen`
                            }
                            titleAction={
                                <>
                                    {item?.name && (
                                        <>
                                            {item.relations > 0 ? (
                                                <Tooltip title="Kann nicht gelöscht werden, da bereits in Verwendung.">
                                                    <span>
                                                        <IconButton disabled>
                                                            <Delete />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            ) : (
                                                <DeleteAction
                                                    label={`${dataset.singular} löschen`}
                                                    action={`data-sets/${dataset.slug}/${id}`}
                                                    onDeleteHandler={onDelete}
                                                    icon={Delete}
                                                >
                                                    <>
                                                        Sind Sie sicher, dass{' '}
                                                        <strong>
                                                            {item.name}
                                                        </strong>{' '}
                                                        unwiderruflich gelöscht
                                                        werden soll?
                                                    </>
                                                </DeleteAction>
                                            )}
                                        </>
                                    )}
                                    <IconButton color="secondary" type="submit">
                                        <Save />
                                    </IconButton>
                                </>
                            }
                        >
                            {dataset.attributes.map(attribute => (
                                <Field
                                    key={attribute}
                                    component={TextField}
                                    name={attribute}
                                    label={fieldLabels(attribute)}
                                    fullWidth
                                    required
                                    autoFocus
                                />
                            ))}
                        </CrmCard>
                    </FormBuilder>
                )}
            </Grid>
        </Grid>
    );
};

export default DataSetEditor;

const fieldLabels: (key: string) => string = key => {
    const labels: Obj = {
        position_d: 'Bezeichnung Divers',
        position_m: 'Bezeichnung Männlich',
        position_f: 'Bezeichnung Weiblich',
        group: 'Gruppenbezeichnung',
        cooperation: 'Bezeichnung der Kooperation',
        contact_type: 'Kontakttyp',
        workshop_system: 'Name des Werkstattsystems',
        brand: 'Name des Herstellers',
        accident_consultant: 'Name des Gutachters',
        competitor: 'Name des Wettbewerbers',
        campaign: 'Kampagne',
    };
    return labels[key] ?? `UNDEFINED label '${key}'`;
};
